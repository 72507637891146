import React, { useState } from 'react';
import './css/Key.css';

export default function Key({letter, keyState, onClick, onBackspace}) {
    const [pressed, setPressed] = useState(false);

    function handleClick(e) {
        e.preventDefault();
        setPressed(false);
        if (onBackspace) {
            // Special handler for backspace key
            onBackspace(e);
        } else {
            // Handler for 'letter' keys
            onClick(e);
        }
    }

    function handleTouchStart(e) {
        e.preventDefault();
        setPressed(true);
    }

    function handleTouchMove(e) {
        // On touchscreen devices, a key should only be considered pressed if the pointer is actively over it.
        // This prevents an issue where you can touch a key, move the pointer elsewhere,
        // and then still enter the original key when the touch event ends.
        if (e.targetTouches[0].pageX - e.target.offsetLeft > e.target.offsetWidth || e.targetTouches[0].pageX - e.target.offsetLeft < 0 ||
            e.targetTouches[0].pageY - e.target.offsetTop > e.target.offsetHeight || e.targetTouches[0].pageY - e.target.offsetTop < 0) {
            setPressed(false);
        }
    }

    // We have a handler for both onTouchEnd AND onClick, as using only onClick on mobile browsers
    // could lead to tapping the previous letter if it was close to the current one. On mobile,
    // I think onTouchEnd is much more accurate than onClick, as I don't believe the browser
    // interferes with the event position.
    // 'e.button !== 2' prevents left clicks from triggering a press
    return (
        <span
            className={`key ${onBackspace ? 'backspace' : keyState} ${pressed ? 'pressed' : ''}`}
            onTouchStart={handleTouchStart}
            onTouchEnd={(e) => pressed && handleClick(e)}
            onTouchMove={handleTouchMove}
            onMouseDown={(e) => e.button !== undefined && e.button !== 2 && setPressed(true)}
            onClick={handleClick}
            onMouseLeave={() => setPressed(false)} // Remove highlight if the mouse is moved off a key before being released
        >{onBackspace ? '←' : letter}</span>
    )
}

export function BackspaceKey({onBackspace}) {
    return (
        <Key onBackspace={onBackspace}/>
    )
}