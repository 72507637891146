import React, { useState } from 'react';
import './css/Tutorial.css';
import { TutorialGuess } from './Guess';

export default function Tutorial({onDismiss}) {
    const [showExample, setShowExample] = useState(false);

    function clickShowExample() {
        window.plausible('Show Example');
        setShowExample(true);
    }

    return (
        <div className="tutorial textBlock">
            <p>Each time you guess a five letter word, you'll see some coloured pins appear after it.</p>
            <p>The pins are always ordered green, orange, then white. Their positions are <strong>not</strong> related to the position of letters in your guess, or the answer.</p>
            <p>Each green pin <span className={`pin correctPin skipAnimation`}></span> means a letter in your guess is at the same position in the answer.</p>
            <p>Every orange pin <span className={`pin partialPin skipAnimation`}></span> means a letter in your guess is in the answer, but at a different position.</p>
            <p>White pins <span className={`pin incorrectPin skipAnimation`}></span> mean a letter in your guess doesn't appear in the answer.</p>
            <p>As you work out which letters are in the answer, you can keep track by tapping the tiles in your guess to change their colours. These will also be reflected on the keyboard.</p>

            { !showExample ?
                <input type="button" value="Show Example" className="button" onClick={clickShowExample} />
            :
                <div className="example">
                    <h3>Example</h3>
                    <div>
                        <div><TutorialGuess guess='cough' correct={0} partial={0} /></div>
                        No letters are present in the answer.
                    </div>
                    <div>
                        <div><TutorialGuess guess='grind' correct={0} partial={3} /></div>
                        Three letters are present in the answer, but at different positions.
                    </div>
                    <div>
                        <div><TutorialGuess guess='diner' correct={1} partial={3} /></div>
                        One letter is at the same position in the answer, and three others are in different positions.
                    </div>
                    <div>
                        <div><TutorialGuess guess='codex' correct={3} partial={0} /></div>
                        Three letters are at the same position in the answer.
                    </div>
                    <div>
                        <div><TutorialGuess guess='index' correct={5} partial={0} /></div>
                        The answer is INDEX!
                    </div>
                    <input type="button" value="Play!" className="button" onClick={onDismiss} />
                </div>
            }
        </div>
    )
}
