import React from 'react';
import './css/LetterTile.css';

const LetterState = {
    UNKNOWN: 'unknown',
    PRESENT: 'present',
    POSITION_KNOWN: 'positionKnown',
    PLAYER_RULED_OUT: 'playerRuledOut',
    ELIMINATED: 'eliminated'
}

export default function LetterTile({letter, guessIndex, letterIndex, tileState, onGuessLetterTileTap}) {
    function nextState() {
        if (tileState === LetterState.UNKNOWN) {
            onGuessLetterTileTap(guessIndex, letterIndex, LetterState.PRESENT);
        } else if (tileState === LetterState.PRESENT) {
            onGuessLetterTileTap(guessIndex, letterIndex, LetterState.POSITION_KNOWN);
        } else if (tileState === LetterState.POSITION_KNOWN) {
            onGuessLetterTileTap(guessIndex, letterIndex, LetterState.PLAYER_RULED_OUT);
        } else if (tileState === LetterState.PLAYER_RULED_OUT) {
            onGuessLetterTileTap(guessIndex, letterIndex, LetterState.UNKNOWN);
        }
    }

    return (
        <span className={`letterTile ${tileState}`} onTouchStart={nextState} onTouchEnd={e => e.preventDefault()} onClick={nextState}>{letter}</span>
    )
}
