import React from 'react';

export default function Debug({guessHistory, answer, hasResumed, getTodaysDate}) {
    const showAnswer = false;
    const today = getTodaysDate();

    return (
        <pre className="textBlock">
            Guess History: {guessHistory.map(x => JSON.stringify(x)).join('\n')} <br/>
            Last Played: {localStorage.getItem('datePlayed')} <br/>
            Has Resumed: {hasResumed.toString()} <br/>
            Today: {`${today.getUTCDate()}/${today.getUTCMonth() + 1}/${today.getUTCFullYear()}`} <br/>
            Answer: {showAnswer ? answer : 'hidden!'}
        </pre>
    )
}
