import React from 'react';
import './css/Pins.css';

export default function Pins(props) {
    const { correct, partial, max, skipAnimations } = props;
    const incorrect = max - (correct + partial);
    const pinValues = Array(correct).fill('correct').concat(Array(partial).fill('partial'), Array(incorrect).fill('incorrect'));

    return (
        <div className="pinGroup">
            { pinValues.map((v, i) => <span key={i} className={`pin ${v}Pin ${skipAnimations ? 'skipAnimation' : ''}`}></span>) }
        </div>
    )
}
