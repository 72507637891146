import React, { useState, useEffect } from 'react';
import KeypadLine from './KeypadLine.js';
import './css/Keypad.css';

const keyRows = [
    ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
    ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
    ['z', 'x', 'c', 'v', 'b', 'n', 'm']
];

export default function Keypad({guessHistory, onTappedKey, pressedBackspace}) {
    const [used, setUsed] = useState(false);
    const [letterStates, setLetterStates] = useState([]);

    useEffect(() => {
        const letterStatesByGuess = guessHistory.map(guess => guess.guess.split('').map((letter, i) => ({ letter, state: guess.tileStates[i]}))).flat();
        const keyStates = letterStatesByGuess.reduce((pv, cv) => {
            if (pv[cv.letter]) {
                return { ...pv, [cv.letter]: [...pv[cv.letter], cv.state] }
            } else {
                return { ...pv, [cv.letter]: [cv.state] }
            }
        }, {});

        const guessedLetters = new Set(guessHistory.map(g => g.guess).join('').split(''));
        setLetterStates(keyRows.flat().map(key => {
            let state;
            if (keyStates[key]) {
                const filteredStates = (keyStates[key]).filter(x => x !== 'unknown');
                const flattenedStates = Array.from(new Set(filteredStates));
                if (flattenedStates.length === 1) {
                    state = flattenedStates[0];
                } else if (flattenedStates.length === 2) {
                    if (flattenedStates.includes('positionKnown') && flattenedStates.includes('present')) {
                        state = 'knownAndPartial';
                    } else if (flattenedStates.includes('playerRuledOut')) {
                        state = 'impossible';
                    }
                } else {
                    // TODO: add support for multi-state colourings
                    state = 'unknown';
                }
            } else if (!guessedLetters.has(key)) {
                state = 'unused';
            } else {
                state = 'unknown'
            }
            return { key, state };
        }));
    }, [guessHistory]);

    function usedKeypad() {
        if (!used) {
            window.plausible('Used Keypad');
            setUsed(true);
        }
    }

    return (
        <div className={`keypad`}>
            {keyRows.map((keyRow, i) => 
                <KeypadLine
                    key={i}
                    keys={keyRow}
                    keyStates={letterStates}
                    onClick={usedKeypad}
                    onTappedKey={onTappedKey}
                    onBackspace={pressedBackspace}
                    backspaceButton={i === 2}
                />)}
        </div>
    )
}
