import React, { useState, useEffect, useRef } from 'react';
import allowed_guesses from './word-lists/allowed_guesses.json';
import answers from './word-lists/answers.json';
import GameWon from './GameWon';
import Tutorial from './Tutorial';
import Debug from './Debug';
import Guess from './Guess';
import Keypad from './Keypad';
import './css/Game.css';
import TileControls from './TileControls';
import { SlQuestion, SlClose } from 'react-icons/sl';

const isBeta = process.env.REACT_APP_BETA || false;
const wordLength = 5;
const validAnswerRegex = new RegExp("^[a-z]{" + wordLength + "}$", 'i');

function isValid(guess) {
    return validAnswerRegex.test(guess) && allowed_guesses.includes(guess);
}

function hasAlreadyGuessed(guess, guessHistory) {
    return guessHistory.findIndex(h => h.guess.toLowerCase() === guess.toLowerCase()) !== -1;
}

function getTodaysDate() {
    let now = new Date();
    now = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());
    return now;
}

function getTodaysAnswer() {
    const start = new Date(2022, 2, 26); // This is our "day 0"
    const now = getTodaysDate();

    // We find the number of days since "day 0" and used that as our index.
    const answerIndex = Math.round(Math.abs((now - start) / (24 * 60 * 60 * 1000)));

    // Mod for safety, so we don't spill over the end of the array if this is still
    // running in 8 years time
    return {
        answer: isBeta ? 'cysts' : answers[answerIndex % answers.length],
        gameNumber: answerIndex + 1 // As 0-indexed
    }
}

function compare(guess, answer) {
    const answerArray = answer.toLowerCase().split('');
    const guessArray = guess.toLowerCase().split('');
    const removedIndexes = [];

    // Check for correct letter AND position:
    const fullMatches = guessArray.reduce((previous, guessedLetter, i) => {
        const correspondingAnswerLetter = answerArray[i];
        if (guessedLetter === correspondingAnswerLetter) {
            removedIndexes.push(i);
            return previous + 1;
        }
        return previous;
    }, 0);

    // Remove the exact match indexes from both guess and answer array
    const filteredAnswerArray = answerArray.filter((_, i) => !removedIndexes.includes(i));
    const filteredGuessArray = guessArray.filter((_, i) => !removedIndexes.includes(i));

    let partialMatches = 0;
    filteredGuessArray.forEach((v) => {
        if (filteredAnswerArray.includes(v)) {
            partialMatches++;
            // We need to remove the value from the answer array so that we don't
            // count additional partial matches for this value (unless the guess
            // also includes it multiple times)
            const indexToRemove = filteredAnswerArray.findIndex(e => e === v);
            filteredAnswerArray.splice(indexToRemove, 1);
        }
    });

    return {
        fullMatches,
        partialMatches
    };
}

function iframeHandler() {
    let runningInIframe = false;
    try {
        runningInIframe = window.self !== window.top;
    } catch (e) {
        runningInIframe = true;
    }

    if (runningInIframe) {
        window.top.location.replace("https://stressfle.nikolaus.uk");
    }
}

export default function Game() {
    const [guessHistory, setGuessHistory] = useState([]);
    const [gameWon, setGameWon] = useState(false);
    const [skipAnimationsUntilGuess, setSkipAnimationsUntilGuess] = useState(0);
    const [showInvalidFlash, setShowInvalidFlash] = useState(false);
    const [showDuplicateFlash, setShowDuplicateFlash] = useState(false);
    const [showTutorial, setShowTutorial] = useState(false);
    const [hasResumed, setHasResumed] = useState(false);
    const [userInitiatedGuess, setUserInitiatedGuess] = useState(false);
    const [isDebug, setIsDebug] = useState(false);
    const [answer, setAnswer] = useState('');
    const [gameNumber, setGameNumber] = useState(0);
    const [completedPreviousDay, setCompletedPreviousDay] = useState(false);
    const [guessTiles, setGuessTiles] = useState(['', '', '', '', '']);
    const docRef = useRef();
    const [selectedInputTile, setSelectedInputTile] = useState(0);
    const [excludedLetters, setExcludedLetters] = useState(new Set());
    const [resetLetterTileButtonPresses, setResetLetterTileButtonPresses] = useState(0);
    const [hasUsedLetterTiles, setHasUsedLetterTiles] = useState(false);
    const [tileColourPickerMode, setTileColourPickerMode] = useState('Cycle');
    const [tileColourPickerValue, setTileColourPickerValue] = useState('present');

    iframeHandler();

    useEffect(() => {
        docRef.current?.focus();
    }, []);

    useEffect(() => {
        const today = getTodaysDate();
        const lastPlayed = localStorage.getItem(`${isBeta ? 'beta_' : ''}datePlayed`);
        const gameInfo = getTodaysAnswer();
        setAnswer(gameInfo.answer);
        setGameNumber(gameInfo.gameNumber);

        if (today.valueOf().toString() === lastPlayed) {
            console.log('You last played today!');
            try {
                const lsGuessHistory = JSON.parse(localStorage.getItem(`${isBeta ? 'beta_' : ''}guessHistory`));

                if (Array.isArray(lsGuessHistory)) {
                    setHasResumed(true);
                    // Enable animations for subsequent (manual input) guesses
                    setSkipAnimationsUntilGuess(lsGuessHistory.length - 1);
                    // Recreate the list of excludedLetters, so any letters from guesses with none included in the answer can be pre-highlighted grey
                    repopulateExcludedLetters(lsGuessHistory);
                    setGuessHistory(lsGuessHistory);
                }
            } catch (e) {
                console.error('Problem recovering data from localStorage', e);
            }
        } else if (!lastPlayed) {
            setShowTutorial(true);
        } else {
            // User has played before, but this is their first time on today's word
            // So delete any saved keypad values, as they won't be relevant for the new word
            localStorage.removeItem(`${isBeta ? 'beta_' : ''}letterTiles`);
        }

        if (['localhost', '192.168.1.8'].includes(window.location.hostname)) {
            localStorage.setItem('plausible_ignore', true);
            setShowTutorial(false);
            setIsDebug(true);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem(`${isBeta ? 'beta_' : ''}guessHistory`, JSON.stringify(guessHistory));

        if (guessHistory.length === 1) {
            const today = getTodaysDate();
            localStorage.setItem(`${isBeta ? 'beta_' : ''}datePlayed`, today.valueOf());
        }

        const latestGuess = guessHistory[guessHistory.length - 1];
        if (!gameWon && latestGuess && latestGuess.guess === answer) {
            // Check if the player completed /today's/ puzzle.
            const { answer: currentAnswer } = getTodaysAnswer();
            if (answer !== currentAnswer) {
                setCompletedPreviousDay(true);
                if (userInitiatedGuess) {
                    if (!isBeta) window.plausible('Previous Game Won');
                }
            }
            setGameWon(true);
            if (userInitiatedGuess) {
                // Only send the event if the user has initiated their guess,
                // rather than as a result of repopulating from localStorage
                if (!isBeta) window.plausible('Game Won', { props: { Guesses: guessHistory.length } });
            }
        }
    }, [guessHistory, answer, gameWon, userInitiatedGuess]);

    // This is used to hide the "Reset Tiles" button if the user hasn't used them.
    useEffect(() => {
        for (const { tileStates } of guessHistory) {
            if (tileStates.includes('present') || tileStates.includes('positionKnown') || tileStates.includes('playerRuledOut')) {
                return setHasUsedLetterTiles(true);
            }
            setHasUsedLetterTiles(false);
        }
    }, [guessHistory]);

    // This is used to mark letter tiles from previous guesses as 'eliminated' if
    // they are included in a guess for which no letters are present in the answer.
    useEffect(() => {
        const updatedTileStates = [];
        for (const [guessIndex, { guess, tileStates }] of guessHistory.entries()) {
            for (const [letterIndex, letter] of guess.split('').entries()) {
                if (excludedLetters.has(letter) && tileStates[letterIndex] !== 'eliminated') {
                    // When we need to update tile states for a guess, we copy the existing states...
                    if (!updatedTileStates[guessIndex]) {
                        updatedTileStates[guessIndex] = tileStates;
                    }
                    // ...and override them as needed
                    updatedTileStates[guessIndex][letterIndex] = 'eliminated';
                }
            }
        }

        // Only update guessHistory if there are tileStates to override. Prevents an infinite loop!
        if (updatedTileStates.length > 0) {
            setGuessHistory(prev => prev.map((historyEntry, index) => updatedTileStates[index] ? { ...historyEntry, tileStates: updatedTileStates[index] } : historyEntry))
        }
    }, [excludedLetters, guessHistory]);

    function checkGuess() {
        const guess = guessTiles.join('');

        if (guess.toLowerCase() === '9_b!5') {
            // toggle debug mode
            setIsDebug(v => !v);
            setGuessTiles(['', '', '', '', '']);
            return;
        }

        // iOS allows pressing return to submit the form, even if the answer isn't long enough.
        // This will prevent many invalid guesses.
        if (guess.length !== wordLength) return;

        setUserInitiatedGuess(true);
        setShowInvalidFlash(false);
        setShowDuplicateFlash(false);

        if (hasAlreadyGuessed(guess, guessHistory)) {
            setShowDuplicateFlash(true);
        } else if (isValid(guess)) {
            const result = compare(guess, answer);
            if (!isBeta && result.fullMatches !== wordLength) window.plausible('Guess', { props: { Valid: true, Guess: `${guess} (Valid)` } });

            let tileStates = Array(wordLength).fill('unknown');
            if (result.fullMatches + result.partialMatches === 0) {
                setExcludedLetters(prev => new Set([...prev, ...guess.split('')]));
                tileStates = Array(wordLength).fill('eliminated');
            } else if (result.fullMatches === wordLength) {
                tileStates = Array(wordLength).fill('positionKnown');
            }

            // If a letter has already been excluded (due to appearing in a guess
            // which give NO full or partially correct letters), then pre-highlight
            // it accordingly.
            guess.split('').forEach((letter, i) => {
                if (excludedLetters.has(letter)) {
                    tileStates[i] = 'eliminated';
                }
            });

            setGuessHistory(prev => [...prev, { guess, full: result.fullMatches, partial: result.partialMatches, tileStates }]);
        } else {
            if (!isBeta) window.plausible('Guess', { props: { Valid: false, Guess: `${guess} (Invalid)` } });
            setShowInvalidFlash(true);
        }

        // Clear the input for the next guess
        setGuessTiles(['', '', '', '', '']);
        setSelectedInputTile(0);

        // Reset guess input cursor back to first input
        // formRef.current.elements[0].focus();
    }

    function clearResetLetterTilesConfirmation() {
        // Clear the confirmation text on the button to reset letter tiles,
        // to be triggered when the user interacts with some other element.
        // This is needed for touchscreen devices, as it is easily detectable
        // on desktop using the onMouseLeave event.
        if (resetLetterTileButtonPresses > 0) setResetLetterTileButtonPresses(0)
    }

    function onGuessLetterTileTap(guessIndex, tileIndex, newState) {
        const { guess, full, partial, tileStates } = guessHistory[guessIndex];

        clearResetLetterTilesConfirmation();

        if (full + partial === 0 || full === wordLength || excludedLetters.has(guess[tileIndex])) {
            return;
        }

        if (tileColourPickerMode !== 'Cycle') {
            newState = tileColourPickerValue;
        }
        const updatedGuess = {
            guess,
            full,
            partial,
            tileStates: [...tileStates.slice(0, tileIndex), newState, ...tileStates.slice(tileIndex + 1, tileStates.length)]
        };
        setGuessHistory(prev => [...prev.slice(0, guessIndex), updatedGuess, ...prev.slice(guessIndex + 1, prev.length)]);
    }

    function resetLetterTiles() {
        setGuessHistory(prev => prev.map(({ guess, full, partial }) => {
            let tileStates = Array(wordLength).fill('unknown');
            if (full + partial === 0) {
                tileStates = Array(wordLength).fill('eliminated');
            }

            guess.split('').forEach((letter, i) => {
                if (excludedLetters.has(letter)) {
                    tileStates[i] = 'eliminated';
                }
            });

            return { guess, full, partial, tileStates };
        }));

        setResetLetterTileButtonPresses(0);
        setHasUsedLetterTiles(false);
    }

    function handleVirtualKeyboardClick(letter) {
        clearResetLetterTilesConfirmation();
        // Handles taps on the Stressfle virtual keyboard
        setGuessTiles([...guessTiles.slice(0, selectedInputTile), letter, ...guessTiles.slice(selectedInputTile + 1, wordLength)]);
        if (selectedInputTile < 4) {
            setSelectedInputTile(prev => prev + 1);
        }
    }

    function handleNewInputGuess(event) {
        clearResetLetterTilesConfirmation();
        if (event.metaKey) {
            return;
        }
        // Handles presses from computer keyboards (not mobile device virtual keyboards)
        let goToPrevInput = false;
        let stayOnCurrentInput = false;

        let letter = '';
        if (!(event.keyCode >= 65 && event.keyCode <= 90)) {
            if (event.code === 'Enter') {
                if (guessTiles.filter(g => g === '').length === 0) {
                    checkGuess();
                }
                return;
            } else if (event.code === 'ArrowRight' && selectedInputTile < 4) {
                setSelectedInputTile(prev => prev + 1);
                return;
            } else if (event.code === 'ArrowLeft' && selectedInputTile > 0) {
                setSelectedInputTile(prev => prev - 1);
                return;
            } else if (event.code === 'Backspace') {
                letter = '';
                if (guessTiles[selectedInputTile] !== '') {
                    // If we delete the current character, then stay on this input.
                    stayOnCurrentInput = true;
                } else {
                    // If we press backspace on an empty character, go back to the previous input.
                    goToPrevInput = true;
                }
            } else {
                return;
            }
        } else {
            letter = event.key.toLowerCase();
        }

        // move onto the next input, while there still are some
        if (stayOnCurrentInput) {
            // nop
        } else if (selectedInputTile < 4 && !goToPrevInput) {
            setSelectedInputTile(prev => prev + 1);
        } else if (selectedInputTile > 0 && goToPrevInput) {
            setSelectedInputTile(prev => prev - 1);
        }

        setGuessTiles([...guessTiles.slice(0, selectedInputTile), letter, ...guessTiles.slice(selectedInputTile + 1, wordLength)]);
    }

    function handleVirtualKeyboardDelete() {
        if (guessTiles[selectedInputTile] === '') {
            if (selectedInputTile > 0) {
                setSelectedInputTile(prev => prev - 1);
            }
        } else {
            setGuessTiles([...guessTiles.slice(0, selectedInputTile), '', ...guessTiles.slice(selectedInputTile + 1, wordLength)]);
        }
    }

    function repopulateExcludedLetters(guessHistory) {
        const excludedLettersArray = guessHistory.reduce((acc, { full, partial, guess }) => {
            if (full + partial === 0) {
                return [...acc, ...guess.split('')];
            } else {
                return acc;
            }
        }, []);

        setExcludedLetters(new Set(excludedLettersArray));
    }

    return (
        <div className="Game" tabIndex={-1} onKeyDown={handleNewInputGuess} ref={docRef}>
            <header className="header">
                <h2>{isBeta ? `Testfle` : 'Stressfle'} #{gameNumber}</h2>
                { showTutorial ?
                    <SlClose className="help" onClick={() => setShowTutorial(false)} /> :
                    <SlQuestion className="help" onClick={() => setShowTutorial(true)} />
                }
            </header>
            <section style={{ paddingBottom: '1.5rem' }}>
                { isDebug && <Debug guessHistory={guessHistory} answer={answer} hasResumed={hasResumed} getTodaysDate={getTodaysDate} />}
                { showTutorial ?
                    <Tutorial onDismiss={() => setShowTutorial(false)}/> :
                    <>
                    {(guessHistory.length < 1) && <p>Enter a five letter word to begin</p>}
                        <>
                            {guessHistory.length > 0 && <>
                                {hasUsedLetterTiles &&
                                    <input
                                        type="button"
                                        value={resetLetterTileButtonPresses === 0 ? "Reset Tiles" : "Sure?"}
                                        className="button warning"
                                        onClick={() => resetLetterTileButtonPresses === 0 ? setResetLetterTileButtonPresses(1) : resetLetterTiles()}
                                        onMouseLeave={() => setResetLetterTileButtonPresses(0)}
                                    />}
                                <TileControls tileColourPickerMode={tileColourPickerMode} setTileColourPickerMode={setTileColourPickerMode} tileColourPickerValue={tileColourPickerValue} setTileColourPickerValue={setTileColourPickerValue} />
                                <div className="guesses">
                                    {guessHistory.map((guess, i) => <Guess key={guess.guess} data={guess} guessIndex={i} skipAnimationsUntilGuess={skipAnimationsUntilGuess} tileStates={guessHistory[i].tileStates} onGuessLetterTileTap={(guessIndex, tileIndex, newState) => onGuessLetterTileTap(guessIndex, tileIndex, newState)}/>)}
                                </div>
                            </>}
                            {!gameWon && <>
                                <div className="newGuessInputContainer">
                                    <span className={`guessInputTile ${selectedInputTile === 0 ? 'selected' : ''}`} onClick={() => setSelectedInputTile(0)}>{guessTiles[0]}</span>
                                    <span className={`guessInputTile ${selectedInputTile === 1 ? 'selected' : ''}`} onClick={() => setSelectedInputTile(1)}>{guessTiles[1]}</span>
                                    <span className={`guessInputTile ${selectedInputTile === 2 ? 'selected' : ''}`} onClick={() => setSelectedInputTile(2)}>{guessTiles[2]}</span>
                                    <span className={`guessInputTile ${selectedInputTile === 3 ? 'selected' : ''}`} onClick={() => setSelectedInputTile(3)}>{guessTiles[3]}</span>
                                    <span className={`guessInputTile ${selectedInputTile === 4 ? 'selected' : ''}`} onClick={() => setSelectedInputTile(4)}>{guessTiles[4]}</span>
                                    <input type="submit" value="Guess" className="button guessButton" disabled={gameWon || guessTiles.filter(g => g === '').length > 0} onClick={checkGuess} />
                                </div>
                                <div className={`message invalidGuess ${showInvalidFlash ? `fadeOut` : 'flashMessageOff'} `} onAnimationEnd={() => setShowInvalidFlash(false)}>Invalid guess!</div>
                                <div className={`message duplicateGuess ${showDuplicateFlash ? `fadeOut` : 'flashMessageOff'} `} onAnimationEnd={() => setShowDuplicateFlash(false)}>Already tried that!</div>
                                <Keypad guessHistory={guessHistory} onTappedKey={handleVirtualKeyboardClick} pressedBackspace={handleVirtualKeyboardDelete}/>
                            </>}
                        </>
                        {gameWon && <GameWon guessHistory={guessHistory} gameNumber={gameNumber} completedPreviousDay={completedPreviousDay} />}

                    </>
                }
            </section>
            <footer style={{ position: 'absolute', bottom: 0, width: '100%', height: '1.5rem', fontSize: '0.8rem' }}>
                Stressfle v1.3.2 by Alex Cummins
            </footer>
        </div>
    )
}
