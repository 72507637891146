import React, { useState } from 'react';
import './css/GameWon.css';
const isBeta = process.env.BETA || false;

const wordLength = 5;

function getGuessScoreString(numGuesses) {
    return `${numGuesses} guess${numGuesses > 1 ? 'es' : ''}`;
}

function nextGameStarts() {
    const now = new Date();
    const nextGameStarts = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1));

    const hoursUntil = Math.floor((nextGameStarts - now) / (60 * 60 * 1000));
    const hoursString = `${hoursUntil > 0 ? `${hoursUntil} hour${hoursUntil > 1 ? 's' : ''}` : ''}`;

    const minutesUntil = Math.floor((nextGameStarts - now) / (60 * 1000) - hoursUntil * 60);
    const minutesString = `${minutesUntil > 0 ? `${minutesUntil} minute${minutesUntil > 1 ? 's' : ''}` : ''}`;

    const result = `${hoursString}${hoursString && minutesString ? ' and ' : ''}${minutesString}`;

    // Capture the weird case of this running right as the new word is available.
    if (result === "  ") {
        return "NOW!?";
    }

    return result;
}

function getGuessEmoji(guessHistory, shouldTruncate) {
    const result = [];

    // Logic so we can truncate longer guess histories
    let skipIndexes = [];
    let needsTruncating = false;
    const preGapGuesses = 5;
    const postGapGuesses = 7;
    if (guessHistory.length > preGapGuesses + postGapGuesses + 1) {
        needsTruncating = true;
        for (let i = preGapGuesses; i <= guessHistory.length - (postGapGuesses + 1); i++) {
            skipIndexes.push(i);
        }
    }

    guessHistory.forEach((guess, index) => {
        if (shouldTruncate && needsTruncating) {
            if (index === preGapGuesses) {
                result.push(`... x${skipIndexes.length}`);
                return;
            } else if (skipIndexes.includes(index)) {
                return;
            }
        }
        const { full, partial } = guess;
        const wrong = wordLength - (full + partial);
        result.push('🟢'.repeat(full) + '🟡'.repeat(partial) + '⚪'.repeat(wrong));
    });

    return result.join('\n');
}

export default function GameWon({guessHistory, gameNumber, completedPreviousDay}) {
    const [showFlash, setShowFlash] = useState(false);
    const [showShareFallback, setShowShareFallback] = useState(false);
    const guessesToWin = guessHistory.length;
    const guessScoreString = getGuessScoreString(guessesToWin);

    const emoji = getGuessEmoji(guessHistory, true);

    async function copyToClipboard() {
        try {
            // For mobile devices which support it, show the platform-native share sheet
            // Mac Safari opens the bad macOS share sheet, so we pretend that doesn't exist.
            if (navigator.share && !navigator.platform.startsWith('Mac') && !navigator.platform.startsWith('Win')) {
                await navigator.share({
                    title: 'Stressfle',
                    text: `#Stressfle ${gameNumber}: ${guessScoreString}\n${emoji}\nhttps://stressfle.nikolaus.uk`
                });
                if (!isBeta) window.plausible('Share', { props: { Type: 'Platform' } });
            } else {
                // Otherwise, just copy to the clipboard
                await navigator.clipboard.writeText(`#Stressfle ${gameNumber}: ${guessScoreString}\n${emoji}\nhttps://stressfle.nikolaus.uk`);
                setShowFlash(true);
                setTimeout(() => setShowFlash(false), 5000);
                if (!isBeta) window.plausible('Share', { props: { Type: 'Clipboard' } });
            }
        } catch (e) {
            setShowShareFallback(true);
            if (!isBeta) window.plausible('Share', { props: { Type: 'Fallback' } });
            console.error('No supported share method:', e);
        }
    }

    return (
        <div>
            <p className="textBlock">
                { !completedPreviousDay ? <>Well done, you've completed today's Stressfle in {guessScoreString}!
                The next word will be available in {nextGameStarts()}.</>
                :
                <>Well done, you've completed Stressfle #{gameNumber} in {guessScoreString}. The next word is already available! Reload the page when you're ready.</>
                }
            </p>
            { !isBeta ?
                <>
            <input type="button" value="Share" className="button shareButton" onClick={() => copyToClipboard(guessesToWin)} />
            { showFlash && <p className={showFlash ? `fadeOut` : ''}>Copied to clipboard</p> }
            { showShareFallback && <div>
                <p style={{ fontSize: '0.8rem'}}>Click in the textbox select your result:</p>
                <textarea className='fallbackShareBox' onClick={(e) => e.target.select()} readOnly rows='5' cols='22' value={`#Stressfle ${gameNumber}: ${guessScoreString}\n${emoji}\nhttps://stressfle.nikolaus.uk`}></textarea>
            </div>}
            </> : null}
        </div>
    )
}
