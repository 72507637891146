import React from 'react';
import './css/KeypadLine.css';
import Key, { BackspaceKey } from './Key.js'

export default function KeypadLine({keys, onClick, keyStates, onTappedKey, backspaceButton, onBackspace}) {
    return (
        <div
            onClick={onClick}
            className={`keypadLine ${backspaceButton ? 'hasBackspaceButton' : ''}`}
        >
            {keys.map((letter, i) => 
                <Key
                    key={i}
                    letter={letter}
                    keyState={keyStates?.find(({key}) => key === letter)?.state || 'unknown'}
                    onClick={() => onTappedKey(letter)} 
                />)
            }
            { backspaceButton && <BackspaceKey onBackspace={onBackspace} /> }
        </div>
    )
}
