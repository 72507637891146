import React from 'react';
import './css/TileControls.css';
import { HiPaintBrush } from 'react-icons/hi2';


export default function TileControls({ tileColourPickerValue, setTileColourPickerValue, tileColourPickerMode, setTileColourPickerMode }) {
    function toggleMode() {
        if (tileColourPickerMode === 'Cycle') {
            setTileColourPickerMode('Single');
        } else {
            setTileColourPickerMode('Cycle');
        }
    }

    return (
        <>
            <input
                type="button"
                value={`Tile Mode: ${tileColourPickerMode}`}
                className={`button tileColourPicker`}
                onClick={toggleMode}
            />
            { tileColourPickerMode === 'Single' && 
                <div>
                    <span className={`colourPicker present ${tileColourPickerValue === 'present' ? 'active' : ''}`} onClick={() => setTileColourPickerValue('present')}><HiPaintBrush/></span>
                    <span className={`colourPicker positionKnown ${tileColourPickerValue === 'positionKnown' ? 'active' : ''}`} onClick={() => setTileColourPickerValue('positionKnown')}><HiPaintBrush/></span>
                    <span className={`colourPicker playerRuledOut ${tileColourPickerValue === 'playerRuledOut' ? 'active' : ''}`} onClick={() => setTileColourPickerValue('playerRuledOut')}><HiPaintBrush/></span>
                    <span className={`colourPicker unknown ${tileColourPickerValue === 'unknown' ? 'active' : ''}`} onClick={() => setTileColourPickerValue('unknown')}><HiPaintBrush/></span>
                </div>
            }
        </>
    )
}
