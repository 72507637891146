import React from 'react';
import Pins from './Pins.js';
import LetterTile from './LetterTile.js';

const wordLength = 5;

export default function Guess({data, guessIndex, skipAnimationsUntilGuess, tileStates, onGuessLetterTileTap}) {
    const {guess, full, partial} = data;

    return (
        <div className="guess">
            <span>{guess.split('').map((char, i) => <LetterTile key={i} letter={char} guessIndex={guessIndex} letterIndex={i} tileState={tileStates[i]} onGuessLetterTileTap={onGuessLetterTileTap}/>)}</span>
            <Pins correct={full} partial={partial} max={wordLength} skipAnimations={skipAnimationsUntilGuess >= guessIndex} />
        </div>
    )
}

// A simplified version of "Guess" used for the examples in the tutorial
export function TutorialGuess({ guess, correct, partial }) {
    return (
        <div className="guess">
            <span>{guess.split('').map((char, i) => <LetterTile key={i} letter={char} letterIndex={i} tileState={'unknown'} onGuessLetterTileTap={() => null}/>)}</span>
            <Pins correct={correct} partial={partial} max={wordLength} skipAnimations={true} />
        </div>
    )
}